<template>
  <div class="col-sm-12">
    <div class="col-sm-10 offset-sm-1">
      <div class="row">
        <h1 class="col-sm-12">Sub asignación</h1>
      </div>

      <div class="row mb-4">
        <div class="col-sm-12">
          Por favor, selecciona la notaria que llevará acabo el proceso de formalziación
        </div>
      </div>

      <div class="row mb-4">
        <label for="" class="col-form-label col-md-2 col-sm-3">Notaría</label>
        <div class="col-sm-9">
          <select name="" id="" class="form-control">
            <option v-for="oficina in empresa.oficinas" :key="oficina.id" :value="oficina.id">{{ oficina.nombre }}</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 text-right">
          <button class="btn btn-warning mr-2">Retroceder etapa</button>
          <button class="btn btn-success" @click="$emit('avanzar')">Continuar</button>
        </div>
      </div>
    </div>

    <NoViable v-if="no_viable" :solicitud="solicitud" />
  </div>
</template>

<script type="text/javascript">
  import apiUsuarios from '@/apps/formalizacion/api/v2/usuarios'
  import apiSolicitudes from '@/apps/formalizacion/api/v2/solicitudes'

  import NoViable from '@/apps/formalizacion/Componentes/NoViable'
  
  export default {
    components: {
      NoViable
    }
    ,data() {
      return {
        empresa: {
          oficinas: []
        }
        ,oficina: null
        ,no_viable: false
      }
    }
    ,mounted() {
      this.obtener_oficinas();
    }
    ,methods: {
      async obtener_oficinas() {
        try {
          this.empresa = (await apiUsuarios.cuenta_obtener_empresa()).data;

          if (this.empresa.oficinas.length == 1) {
            this.oficina = this.empresa.oficinas[0].id
            this.definir_oficina();
          }

          this.$log.info('empresa',this.empresa)
        }catch(e) {
          this.$log.info('error', e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      , async definir_oficina() {
        try {
          let res = (await apiSolicitudes.definir_oficina(this.$route.params.solicitud, this.oficina)).data;
          this.$log.info('res', res);

          this.$emit('avanzar');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>